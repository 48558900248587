




















































import Vue, { PropType } from 'vue';

type Pagination = {
  sortBy: string;
  descending: boolean;
  page: number;
  rowsPerPage: number;
  rowsNumber: number;
};

type PaginationScope = {
  pagination: Pagination;
  pagesNumber: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  inFullscreen: boolean;
};

export default Vue.extend({
  props: {
    // This will be used with a scoped slot props object
    scope: {
      type: Object as PropType<PaginationScope>,
      required: true,
    },
  },
});
