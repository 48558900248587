<template>
  <q-item class="user-card"  :class="{ small: !fullDetails }">
    <q-item-section :avatar="fullDetails">
      <AppAvatar
        size="55px"
        class="q-mx-auto"
        :avatar="details.avatar"
        :label="details.displayName"
      />
      <SocialDetails
        v-if="details.social && fullDetails"
        size="sm"
        :details="details.social"
        class="q-mt-md"
      />
    </q-item-section>
    <q-item-section top>
      <p class="text-subtitle2 q-my-none">{{ details.displayName }}</p>
      <span class="caption q-mb-md" v-if="details.title">{{ details.title }}</span>
    </q-item-section>
  </q-item>
</template>

<script>
import AppAvatar from '@/components/avatar/Avatar.vue';
import SocialDetails from '@/components/social/SocialDetails.vue';

export default {
  name: 'UserCard',
  components: { SocialDetails, AppAvatar },
  props: {
    fullDetails: Boolean,
    details: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "~quasar-variables";

.user-card {
  border-radius: $generic-border-radius;
  max-width: 350px;

  &.small {
    max-width: 150px;
  }
}
</style>
