











import Vue, { PropType } from 'vue';
import ListTooltip from '@/components/list/ListTooltip.vue';
import UserCard from '@/components/card/UserCard.vue';
import { CreatorData } from '@/types/list';

type CreatedBy = {
  displayName: string;
  avatar: string;
};

type UserRow = {
  createdBy: string;
}

export default Vue.extend({
  name: 'CreatedByCol',
  components: { UserCard, ListTooltip },
  props: {
    row: {
      type: Object as PropType<UserRow>,
      required: true,
    },
    creators: {
      type: Object as PropType<CreatorData>,
      required: true,
    },
  },
  computed: {
    createdBy(): CreatedBy {
      const { createdBy } = this.row;

      if (this.creators[createdBy]) {
        return this.creators[createdBy];
      }

      return {
        displayName: 'System',
        avatar: '',
      };
    },
  },
});
