











import Vue, { PropType } from 'vue';
import { Pagination } from '@/types/list';

export default Vue.extend({
  props: {
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
  },
  computed: {
    rowsNumberMessage(): string {
      const { page, rowsPerPage, rowsNumber } = this.pagination;

      const low = ((page - 1) * rowsPerPage) + 1;
      const high = Math.min(page * rowsPerPage, rowsNumber);

      return `Showing ${low}-${high} / ${rowsNumber}`;
    },
  },
});
