import { render, staticRenderFns } from "./List.vue?vue&type=template&id=590a1068&"
import script from "./List.vue?vue&type=script&lang=ts&"
export * from "./List.vue?vue&type=script&lang=ts&"
import style0 from "./List.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QTh,QCheckbox,QTr,QTd});
