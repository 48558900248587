

































































































































import Vue, { PropType } from 'vue';
import ListRowsMessage from '@/components/list/ListRowsMessage.vue';
import ApiService from '@/services/api.service';
import {
  Pagination,
  TableColumn,
  RequestConfig,
  SearchResponse,
  CreatorData,
} from '@/types/list';
import ApiError from '@/components/ApiError.vue';
import ListPagination from '@/components/list/ListPagination.vue';
import CreatedByCol from '@/components/list/CreatedByCol.vue';
import Btn from '@/components/buttons/Btn.vue';

const ROWS_PER_PAGE = 10;

const defaultPagination: Pagination = {
  page: 1,
  rowsNumber: 0,
  descending: true,
  sortBy: 'createdAt',
  rowsPerPage: ROWS_PER_PAGE,
  existing: 0,
};

export default Vue.extend({
  name: 'List',
  components: {
    ListRowsMessage,
    Btn,
    ApiError,
    CreatedByCol,
    ListPagination,
  },
  props: {
    flat: Boolean,
    bordered: Boolean,
    selectableRows: Boolean,
    listTitle: String,
    name: {
      type: String,
      required: true,
    },
    labelField: {
      type: String,
      default: 'label',
    },
    colLabel: {
      type: String,
      default: 'Name',
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    requestConfig: {
      type: Object as PropType<RequestConfig>,
      required: true,
    },
    extraColumns: {
      type: Array as PropType<TableColumn[]>,
      required: false,
      default() {
        return [];
      },
    },
    withDatetime: Boolean,
    withRowActions: Boolean,
    withCreatedBy: {
      type: Boolean,
      default: true,
    },
    withUpdatedAt: Boolean,
    defaultData: {
      type: Object as PropType<SearchResponse>,
      required: false,
    },
  },
  computed: {
    pluralName(): string {
      if (this.name[this.name.length - 1] === 's') {
        return `${this.name}es`;
      }
      if (this.name[this.name.length - 1] === 'y') {
        return `${this.name.substring(0, this.name.length - 1)}ies`;
      }
      return `${this.name}s`;
    },
  },
  data() {
    return {
      apiError: '',
      loading: false,
      rows: [] as any[],
      columns: [] as TableColumn[],
      creators: {} as CreatorData,
      selectedRows: [] as string[],
      pagination: defaultPagination,
    };
  },
  methods: {
    hasScopedSlot(slotName = 'default'): boolean {
      const slot = this.$scopedSlots[slotName];
      // the scoped slots can contain a function even if no parameters have been passed since
      // this is used by the ListWithFilter.vue class
      // calling the function with 'undefined' checks inside properly
      return !!slot && !!slot('');
    },
    isSet(val: any): boolean {
      return val !== null && val !== undefined;
    },
    hasSearch() {
      const { params } = this.requestConfig;
      if (!params) {
        return false;
      }
      const { search, creators, dateFrom, dateTo } = params;
      const hasSearch = search && search.length > 0;
      const hasCreators = creators && creators.length > 0;
      const hasDateTo = dateTo && dateTo !== new Date().toLocaleDateString();

      return hasSearch || hasCreators || dateFrom || hasDateTo;
    },
    async clearAndSearch() {
      await this.loadRows({ pagination: defaultPagination });
      this.$emit('search:clear');
    },
    getSearchQuery() {
      return {
        ...this.pagination,
        ...this.requestConfig.params,
      };
    },
    async loadRows(props?: { pagination: Pagination }) {
      this.loading = true;
      this.selectedRows = [];
      this.apiError = '';

      try {
        if (props && props.pagination) {
          this.pagination = props.pagination;
        }

        const data: SearchResponse = await ApiService.get(
          this.requestConfig.url,
          this.getSearchQuery(),
        );

        this.setRowsAndPagination(data);
        this.$emit('search:done', data);
      } catch {
        this.apiError = `Could not load ${this.name}. Please try again later`;
      }
      this.loading = false;
    },
    setRowsAndPagination(data: SearchResponse) {
      this.rows = data.results || [];
      this.pagination = { ...this.pagination, ...data.query };
      this.pagination.rowsNumber = data.total;
      this.pagination.existing = data.existing || data.total;

      if (data.creators) {
        this.creators = data.creators;
      }
    },
    setColCheckbox() {
      if (this.selectedRows.length > 0) {
        this.selectedRows = [];
      } else {
        this.selectedRows = this.rows
          .filter((object) => object.deletable !== false)
          .map((object) => object.id);
      }
      this.$emit('select', this.selectedRows);
    },
    getColCheckboxState(): boolean | string {
      if (this.selectedRows.length === this.rows.length) {
        return true;
      }
      if (this.selectedRows.length === 0) {
        return false;
      }
      return 'maybe';
    },
    onSelectRow(id: string, selected: boolean) {
      if (selected) {
        this.selectedRows.push(id);
      } else {
        this.selectedRows = this.selectedRows.filter((r) => r !== id);
      }
      this.$emit('select', this.selectedRows);
    },
    isSelected(id: string) {
      return this.selectedRows.find((r) => r === id) !== undefined;
    },
    generateColumns() {
      const labelCol: TableColumn = {
        name: this.labelField,
        label: this.colLabel,
        field: this.labelField,
        align: 'left',
        sortable: this.sortable,
      };

      const lastCols: TableColumn[] = this.withCreatedBy ? [
        {
          name: 'createdBy',
          label: 'Created by',
          field: 'createdBy',
          align: 'center',
          sortable: this.sortable,
        },
        {
          name: 'createdAt',
          label: 'Created at',
          field: 'createdAt',
          align: 'center',
          sortable: this.sortable,
        },
      ] : [];

      if (this.withUpdatedAt) {
        lastCols.push({
          name: 'updatedAt',
          label: 'Updated at',
          field: 'updatedAt',
          align: 'center',
          sortable: this.sortable,
        });
      }

      if (this.withRowActions) {
        lastCols.push({
          name: 'view',
          label: '',
          field: 'id',
          align: 'right',
          sortable: false,
        });
      }

      this.validateExtraColumns();

      const firstCols: TableColumn[] = [];
      if (this.selectableRows) {
        firstCols.push({
          name: 'select',
          label: '',
          field: 'select',
          align: 'center',
          sortable: false,
        });
      }
      firstCols.push(labelCol);

      if (this.extraColumns.length) {
        return [...firstCols, ...this.extraColumns, ...lastCols];
      }

      return [...firstCols, ...lastCols];
    },
    validateExtraColumns() {
      if (this.extraColumns.length && !this.$scopedSlots.extraColumns) {
        console.warn('extraColumns PROP passed, but extraColumns SLOT is missing');
      }
      if (!this.extraColumns.length && this.$scopedSlots.extraColumns) {
        console.warn('extraColumns SLOT detected, but extraColumns PROP is missing');
      }
    },
  },
  async created() {
    if (this.defaultData) {
      this.setRowsAndPagination(this.defaultData);
    } else {
      await this.loadRows();
    }
  },
  mounted() {
    this.columns = this.generateColumns();
  },
});
